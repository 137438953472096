import {TileLayer} from '@deck.gl/geo-layers';
import {tile2boundingBox} from '@deck.gl/geo-layers/dist/esm/tile-layer/utils/tile';
import {ArcLayer, GeoJsonLayer, PointCloudLayer, TextLayer} from '@deck.gl/layers';
import GL from '@luma.gl/constants';
import {VectorTile} from '@mapbox/vector-tile';
import {along, bbox, circle, explode, length, lineString, pointGrid, transformScale, union} from '@turf/turf';
import {
  color,
  csvParse,
  interpolateCool,
  interpolateGreens,
  interpolateRainbow,
  interpolateYlOrRd,
  scaleSequential
} from 'd3';
import DeckGL, {FlyToInterpolator} from 'deck.gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import Protobuf from 'pbf';
import React from 'react';
import {StaticMap} from 'react-map-gl';
import * as highways from '../assets/data/dchighways.json';
import RandomColorGenerator from './RandomColorGenerator';

// Set your mapbox access token here
const MAPBOX_ACCESS_TOKEN = '';
const MOBILE_WIDTH = 600;

// Initial viewport settings
const initialViewState = {
  latitude: 38.902527,
  longitude: -77.052644,
  zoom: 16,
  pitch: 0,
  bearing: 0,
  transitionDuration: 3000,
  transitionInterpolator: new FlyToInterpolator()
};

const featureCodes = [
  {
    attributes: {
      DESCRIPTION: 'Building '
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Bleacher'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Building'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Tennis Court'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Sidewalk'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Other Recreational Court'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Helipad'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Intersection'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Basketball Court'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Wastewater Holding Ponds'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Stairs'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Fountain'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Pool'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Swimming Pool'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Paved Drive'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Road'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Baseball Field'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Track'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Taxiway Apron'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Other Recreational Area'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Paved Traffic Island'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Parking Lot'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Airport Perimeter'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Alley'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Paved Median Island'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Soccer Field'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Memorial'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Parking Garage'
    }
  },
  {
    attributes: {
      DESCRIPTION: 'Football Field'
    }
  }
];

const sciNmCodes = [
  {
    attributes: {
      SCI_NM: 'Quercus phellos'
    }
  },
  {
    attributes: {
      SCI_NM: 'Acer rubrum'
    }
  },
  {
    attributes: {
      SCI_NM: 'Fraxinus pennsylvanica lanceolata'
    }
  },
  {
    attributes: {
      SCI_NM: null
    }
  },
  {
    attributes: {
      SCI_NM: 'Malus species'
    }
  },
  {
    attributes: {
      SCI_NM: 'Zelkova serrata'
    }
  },
  {
    attributes: {
      SCI_NM: 'Quercus rubra'
    }
  },
  {
    attributes: {
      SCI_NM: 'Liquidambar rotundiloba'
    }
  },
  {
    attributes: {
      SCI_NM: 'Ulmus parvifolia'
    }
  },
  {
    attributes: {
      SCI_NM: 'Acer campestre'
    }
  },
  {
    attributes: {
      SCI_NM: 'Magnolia x soulangeana'
    }
  },
  {
    attributes: {
      SCI_NM: 'Juniperus virginiana'
    }
  },
  {
    attributes: {
      SCI_NM: 'Acer species'
    }
  },
  {
    attributes: {
      SCI_NM: 'Robinia pseudoacacia'
    }
  },
  {
    attributes: {
      SCI_NM: 'Longleaf pine Pinus palustris'
    }
  },
  {
    attributes: {
      SCI_NM: 'Amelanchier laevis'
    }
  },
  {
    attributes: {
      SCI_NM: "Malus 'Arnold'"
    }
  },
  {
    attributes: {
      SCI_NM: "Tilia cordata 'Glenleven"
    }
  },
  {
    attributes: {
      SCI_NM: 'Carya species'
    }
  },
  {
    attributes: {
      SCI_NM: 'Cryptomeria japonica'
    }
  },
  {
    attributes: {
      SCI_NM: 'No Tree'
    }
  },
  {
    attributes: {
      SCI_NM: 'Quercus stellata'
    }
  },
  {
    attributes: {
      SCI_NM: 'Quercus montana'
    }
  },
  {
    attributes: {
      SCI_NM: 'Prunus mume'
    }
  },
  {
    attributes: {
      SCI_NM: 'Chionanthus retusus'
    }
  },
  {
    attributes: {
      SCI_NM: "Malus 'Radiant'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Laburnum species'
    }
  },
  {
    attributes: {
      SCI_NM: 'Magnolia acuminata'
    }
  },
  {
    attributes: {
      SCI_NM: 'Tilia euchlora'
    }
  },
  {
    attributes: {
      SCI_NM: "Acer platanoides 'Emerald Queen'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Quercus virginiana'
    }
  },
  {
    attributes: {
      SCI_NM: "Ulmus americana 'New Harmony'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Cercis canadensis'
    }
  },
  {
    attributes: {
      SCI_NM: 'Tilia species'
    }
  },
  {
    attributes: {
      SCI_NM: 'Carpinus betulus'
    }
  },
  {
    attributes: {
      SCI_NM: 'Cercis species'
    }
  },
  {
    attributes: {
      SCI_NM: 'Acer ginnala'
    }
  },
  {
    attributes: {
      SCI_NM: "Tilia cordata 'Greenspire'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Pinus taeda'
    }
  },
  {
    attributes: {
      SCI_NM: 'Quercus imbricaria'
    }
  },
  {
    attributes: {
      SCI_NM: 'Quercus prinus'
    }
  },
  {
    attributes: {
      SCI_NM: "Acer rubrum 'October Glory'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Carpinus species'
    }
  },
  {
    attributes: {
      SCI_NM: 'Cercidiphyllum japonicum'
    }
  },
  {
    attributes: {
      SCI_NM: "Betula nigra 'Dura heat'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Pinus strobus'
    }
  },
  {
    attributes: {
      SCI_NM: 'Tsuga canadensis'
    }
  },
  {
    attributes: {
      SCI_NM: "Tilia americana 'Redmond'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Maackia amurensis'
    }
  },
  {
    attributes: {
      SCI_NM: "Ginkgo biloba 'Princeton sentry'"
    }
  },
  {
    attributes: {
      SCI_NM: "Crataegus oxyacantha superba 'Crimson Cloud'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Ulmus hollandica Blandford'
    }
  },
  {
    attributes: {
      SCI_NM: 'Crataegus phaenopyrum'
    }
  },
  {
    attributes: {
      SCI_NM: 'Quercus bicolor'
    }
  },
  {
    attributes: {
      SCI_NM: 'Ulmus americana'
    }
  },
  {
    attributes: {
      SCI_NM: 'Liquidambar styraciflua'
    }
  },
  {
    attributes: {
      SCI_NM: 'Celtis occidentalis'
    }
  },
  {
    attributes: {
      SCI_NM: 'Quercus nuttallii'
    }
  },
  {
    attributes: {
      SCI_NM: 'Gleditsia triacanthos var. inermis'
    }
  },
  {
    attributes: {
      SCI_NM: 'Acer buergeranum'
    }
  },
  {
    attributes: {
      SCI_NM: "Gleditsia triacanthos var. inermis 'Shademaster'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Aesculus carnea'
    }
  },
  {
    attributes: {
      SCI_NM: 'Sassafras albidum'
    }
  },
  {
    attributes: {
      SCI_NM: 'Platanus species'
    }
  },
  {
    attributes: {
      SCI_NM: 'Acer negundo'
    }
  },
  {
    attributes: {
      SCI_NM: 'Populus species'
    }
  },
  {
    attributes: {
      SCI_NM: 'Thuja occidentalis'
    }
  },
  {
    attributes: {
      SCI_NM: 'Alnus glutinosa'
    }
  },
  {
    attributes: {
      SCI_NM: "Ulmus hollandica 'Commelin'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Quercus coccinea'
    }
  },
  {
    attributes: {
      SCI_NM: 'Platanus x acerifolia'
    }
  },
  {
    attributes: {
      SCI_NM: 'Lagerstroemia indica'
    }
  },
  {
    attributes: {
      SCI_NM: 'Prunus virginiana'
    }
  },
  {
    attributes: {
      SCI_NM: 'Tilia tomentosa'
    }
  },
  {
    attributes: {
      SCI_NM: "Pyrus calleryana 'Bradford'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Tilia cordata'
    }
  },
  {
    attributes: {
      SCI_NM: 'Quercus alba'
    }
  },
  {
    attributes: {
      SCI_NM: 'Carpinus caroliniana'
    }
  },
  {
    attributes: {
      SCI_NM: 'Koelreuteria paniculata'
    }
  },
  {
    attributes: {
      SCI_NM: 'Ginkgo biloba'
    }
  },
  {
    attributes: {
      SCI_NM: 'Ulmus pumila'
    }
  },
  {
    attributes: {
      SCI_NM: 'Cornus kousa'
    }
  },
  {
    attributes: {
      SCI_NM: 'Ulmus carpinifolia'
    }
  },
  {
    attributes: {
      SCI_NM: 'Liriodendron tulipifera'
    }
  },
  {
    attributes: {
      SCI_NM: 'Rhus typhina'
    }
  },
  {
    attributes: {
      SCI_NM: 'Quercus robur fastigiata'
    }
  },
  {
    attributes: {
      SCI_NM: "Ulmus americana 'Augustine'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Maclura pomifera'
    }
  },
  {
    attributes: {
      SCI_NM: 'Juglans nigra'
    }
  },
  {
    attributes: {
      SCI_NM: "Quercus palustris 'Sovereign'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Fagus sylvatica'
    }
  },
  {
    attributes: {
      SCI_NM: 'Magnolia stellata'
    }
  },
  {
    attributes: {
      SCI_NM: 'Cornus florida var . rubra'
    }
  },
  {
    attributes: {
      SCI_NM: 'Quercus laevis'
    }
  },
  {
    attributes: {
      SCI_NM: "Ulmus hollandica 'Groenveldt'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Other (See Notes)'
    }
  },
  {
    attributes: {
      SCI_NM: "Malus 'Snowdrift'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Halesia carolina'
    }
  },
  {
    attributes: {
      SCI_NM: 'Quercus species'
    }
  },
  {
    attributes: {
      SCI_NM: 'Pistacia chinensis'
    }
  },
  {
    attributes: {
      SCI_NM: "Platanus x acerifolia 'Bloodgood'"
    }
  },
  {
    attributes: {
      SCI_NM: "Fagus sylvatica 'Atropuncea'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Tilia americana'
    }
  },
  {
    attributes: {
      SCI_NM: 'Metasequoia glyptostroboides'
    }
  },
  {
    attributes: {
      SCI_NM: 'Magnolia virginiana'
    }
  },
  {
    attributes: {
      SCI_NM: 'Cornus species'
    }
  },
  {
    attributes: {
      SCI_NM: 'Celtis laevigata'
    }
  },
  {
    attributes: {
      SCI_NM: 'Quercus macrocarpa'
    }
  },
  {
    attributes: {
      SCI_NM: 'Ginkgo biloba (female)'
    }
  },
  {
    attributes: {
      SCI_NM: 'Ilex opaca'
    }
  },
  {
    attributes: {
      SCI_NM: 'Magnolia Species'
    }
  },
  {
    attributes: {
      SCI_NM: 'Fraxinus species'
    }
  },
  {
    attributes: {
      SCI_NM: 'Eucommia ulmoides'
    }
  },
  {
    attributes: {
      SCI_NM: 'Parrotia persica'
    }
  },
  {
    attributes: {
      SCI_NM: 'Fagus grandifolia'
    }
  },
  {
    attributes: {
      SCI_NM: "Pyrus calleryana 'Whitehouse'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Aesculus pavia'
    }
  },
  {
    attributes: {
      SCI_NM: "Gleditsia triacanthos var. inermis 'Moraine'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Paulownia tomentosa'
    }
  },
  {
    attributes: {
      SCI_NM: 'Stewartia pseudocamellia'
    }
  },
  {
    attributes: {
      SCI_NM: 'Crataegus lavallei'
    }
  },
  {
    attributes: {
      SCI_NM: "Acer saccharum 'Green Mountain'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Quercus ilicifolia'
    }
  },
  {
    attributes: {
      SCI_NM: "Gleditsia triacanthos var. inermis 'Sunburst'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Quercus marilandica'
    }
  },
  {
    attributes: {
      SCI_NM: "Ulmus hollandica 'Bea Schwartz'"
    }
  },
  {
    attributes: {
      SCI_NM: "Acer saccharum 'Seneca Chief'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Ginkgo biloba (male)'
    }
  },
  {
    attributes: {
      SCI_NM: "Ulmus 'Accolade'"
    }
  },
  {
    attributes: {
      SCI_NM: "Zelkova serrata 'Green Vase'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Ulmus species'
    }
  },
  {
    attributes: {
      SCI_NM: 'Amelanchier species'
    }
  },
  {
    attributes: {
      SCI_NM: 'Quercus lyrata'
    }
  },
  {
    attributes: {
      SCI_NM: 'Betula nigra'
    }
  },
  {
    attributes: {
      SCI_NM: "Ulmus americana 'Jefferson'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Magnolia grandiflora'
    }
  },
  {
    attributes: {
      SCI_NM: 'Cladrastis lutea'
    }
  },
  {
    attributes: {
      SCI_NM: 'Acer rubrum Columnare'
    }
  },
  {
    attributes: {
      SCI_NM: 'Aesculus flava'
    }
  },
  {
    attributes: {
      SCI_NM: 'Koelreuteria bipinnata'
    }
  },
  {
    attributes: {
      SCI_NM: 'Catalpa bignonioides'
    }
  },
  {
    attributes: {
      SCI_NM: 'Ulmus pioneer'
    }
  },
  {
    attributes: {
      SCI_NM: "Cercis canadensis 'Forest Pansy'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Magnolia Liliflora x sprengeri'
    }
  },
  {
    attributes: {
      SCI_NM: 'Prunus cerasifera'
    }
  },
  {
    attributes: {
      SCI_NM: 'Amelanchier canadensis'
    }
  },
  {
    attributes: {
      SCI_NM: 'Acer pseudoplatanus'
    }
  },
  {
    attributes: {
      SCI_NM: 'Quercus falcata'
    }
  },
  {
    attributes: {
      SCI_NM: 'Salix babylonica'
    }
  },
  {
    attributes: {
      SCI_NM: 'Cotinus obovatus'
    }
  },
  {
    attributes: {
      SCI_NM: "Acer platanoides 'Summershade'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Taxodium ascendens'
    }
  },
  {
    attributes: {
      SCI_NM: 'Ulmus glabra'
    }
  },
  {
    attributes: {
      SCI_NM: 'Sorbus aucuparia'
    }
  },
  {
    attributes: {
      SCI_NM: 'Quercus pagoda'
    }
  },
  {
    attributes: {
      SCI_NM: "Acer saccharum 'Columnare'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Quercus acutissima'
    }
  },
  {
    attributes: {
      SCI_NM: 'Acer griseum'
    }
  },
  {
    attributes: {
      SCI_NM: 'Platanus occidentalis'
    }
  },
  {
    attributes: {
      SCI_NM: 'Acer saccharum'
    }
  },
  {
    attributes: {
      SCI_NM: 'Acer platanoides'
    }
  },
  {
    attributes: {
      SCI_NM: "Gleditsia triacanthos var. inermis 'Skyline'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Nyssa sylvatica'
    }
  },
  {
    attributes: {
      SCI_NM: "Ulmus americana 'Princeton'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Gleditsia species'
    }
  },
  {
    attributes: {
      SCI_NM: 'Betula species'
    }
  },
  {
    attributes: {
      SCI_NM: 'Morus alba'
    }
  },
  {
    attributes: {
      SCI_NM: 'Cornus florida'
    }
  },
  {
    attributes: {
      SCI_NM: 'Persimmon Diospyros Virginia'
    }
  },
  {
    attributes: {
      SCI_NM: "Malus 'Harvest Gold'"
    }
  },
  {
    attributes: {
      SCI_NM: "Acer platanoides 'Crimson King'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Cedrus deodora'
    }
  },
  {
    attributes: {
      SCI_NM: 'Ailanthus altissima'
    }
  },
  {
    attributes: {
      SCI_NM: 'Quercus nigra'
    }
  },
  {
    attributes: {
      SCI_NM: "Styphnolobium japonicum 'Regent'"
    }
  },
  {
    attributes: {
      SCI_NM: "Acer platanoides 'Cleveland'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Alibizia julibrissin'
    }
  },
  {
    attributes: {
      SCI_NM: 'Quercus palustris'
    }
  },
  {
    attributes: {
      SCI_NM: 'Prunus species'
    }
  },
  {
    attributes: {
      SCI_NM: 'Acer saccharinum'
    }
  },
  {
    attributes: {
      SCI_NM: 'Prunus yedoensis'
    }
  },
  {
    attributes: {
      SCI_NM: 'Amelanchier arborea'
    }
  },
  {
    attributes: {
      SCI_NM: 'Picea abies'
    }
  },
  {
    attributes: {
      SCI_NM: "Prunus serrulata 'Kwanzan'"
    }
  },
  {
    attributes: {
      SCI_NM: "Prunus 'Snow Goose'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Syringa reticulata'
    }
  },
  {
    attributes: {
      SCI_NM: 'Taxodium distichum'
    }
  },
  {
    attributes: {
      SCI_NM: "Liquidambar Stryaciflua 'Sterile'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Quercus shumardi'
    }
  },
  {
    attributes: {
      SCI_NM: "Zelkova serrata 'Village Green'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Prunus Okame'
    }
  },
  {
    attributes: {
      SCI_NM: 'Styphnolobium japonicum'
    }
  },
  {
    attributes: {
      SCI_NM: 'Gymnocladus dioicus'
    }
  },
  {
    attributes: {
      SCI_NM: "Liquidambar styraciflua 'Slender Silhouette'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Ostrya virginiana'
    }
  },
  {
    attributes: {
      SCI_NM: 'Aesculus hippocastanum'
    }
  },
  {
    attributes: {
      SCI_NM: "Quercus 'Darlingtoni'"
    }
  },
  {
    attributes: {
      SCI_NM: "Quercus palustris 'Pringreen'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Quercus velutina'
    }
  },
  {
    attributes: {
      SCI_NM: "Fraxinus pennsylvanica lanceolata 'Marshall'"
    }
  },
  {
    attributes: {
      SCI_NM: "Acer platanoides 'Columnare'"
    }
  },
  {
    attributes: {
      SCI_NM: 'Oxydendrum arboreum'
    }
  },
  {
    attributes: {
      SCI_NM: 'Populus caroliniana'
    }
  },
  {
    attributes: {
      SCI_NM: 'Salix species'
    }
  },
  {
    attributes: {
      SCI_NM: 'Fagus grandiflora'
    }
  },
  {
    attributes: {
      SCI_NM: 'Cornus alternifolia'
    }
  },
  {
    attributes: {
      SCI_NM: 'Phellodendron amurense'
    }
  }
];
const featureColors = new RandomColorGenerator(Math.random(), scaleSequential(interpolateRainbow));

const flightColors = new RandomColorGenerator(Math.random(), scaleSequential(interpolateYlOrRd));

const dataDrivenStyle = ['match', ['get', 'DESCRIPTIO']];

featureCodes.forEach(featureCode => {
  const {attributes} = featureCode;
  const {DESCRIPTION} = attributes;
  dataDrivenStyle.push(DESCRIPTION);
  dataDrivenStyle.push(featureColors.next(DESCRIPTION));
});

dataDrivenStyle.push('#ccc');

const treeColors = new RandomColorGenerator(Math.random(), scaleSequential(interpolateGreens));

const treeStyle = ['match', ['get', 'SCI_NM']];

sciNmCodes.forEach(sciNm => {
  const {attributes} = sciNm;
  const {SCI_NM} = attributes;
  treeStyle.push(SCI_NM || '');
  treeStyle.push(treeColors.next(SCI_NM || ''));
});

treeStyle.push('#ccc');

const dataColor = featureColors.next('Data');
const dataColorObj = color(dataColor);
const dataColorDeck = [dataColorObj.r, dataColorObj.g, dataColorObj.b, 255];

const labelColor = featureColors.next('Labels');

// const baseStyle = 'https://api.maptiler.com/maps/darkmatter/style.json?key=oE7Sv0VrAgy6GbbGqWiG';
const washingtonMonumentFeature = {
  type: 'Feature',
  id: 174356,
  geometry: {
    type: 'Polygon',
    coordinates: [
      [
        [-77.035145174227182, 38.889500955727691],
        [-77.035145293262048, 38.889552478545149],
        [-77.035343030369219, 38.889552204929259],
        [-77.035342688368885, 38.889401359746621],
        [-77.035145326290518, 38.889401633249179],
        [-77.035145447933559, 38.889455525833128],
        [-77.035145174227182, 38.889500955727691]
      ]
    ]
  },
  properties: {
    OBJECTID: 174356,
    GIS_ID: null,
    FEATURECODE: 2010,
    DESCRIPTION: 'Memorial',
    CAPTUREYEAR: 1429833600000,
    CAPTUREACTION: 'E',
    SOURCE: 'BldgPly',
    Shape_Length: 67.767625837177334,
    Shape_Area: 286.98555077190559
  }
};
const monumentBands = [washingtonMonumentFeature];
for (let i = 0.95; i > 0.01; i -= 0.01) {
  monumentBands.push(
    transformScale(washingtonMonumentFeature, i, {
      origin: 'center'
    })
  );
}

let layerConfig = {};

const TEXT_DISTANCE = 0.01;

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      features: props.features || [],
      pointFeatures: [],
      pointFeaturePaths: [],
      viewState: initialViewState,
      time: 0,
      spotElevations: [],
      elevationRange: [0, 0],
      contours: [],
      highlightedFeatures: [],
      fountainFeature: [],
      fountainArcs: [],
      buildingFeature: null,
      flights: [],
      hoveredObject: null,
      pointerX: 0,
      pointerY: 0,
      mapWidth: 3000
    };
    this.hoveredStateIds = {};
  }

  componentDidMount() {
    layerConfig = {
      version: 8,
      sources: {
        impervious: {
          type: 'vector',
          tiles: [
            `${window.location.protocol}//${window.location.hostname}:${window.location.port}/dc-tiles/{z}/{x}/{y}.pbf`
          ],
          minzoom: 14,
          maxzoom: 18
        },
        poi: {
          type: 'vector',
          tiles: [
            `${window.location.protocol}//${window.location.hostname}:${window.location.port}/dc-pois/{z}/{x}/{y}.pbf`
          ],
          minzoom: 14,
          maxzoom: 18
        },
        dctrees1: {
          type: 'vector',
          tiles: [
            `${window.location.protocol}//${window.location.hostname}:${window.location.port}/dc-trees/{z}/{x}/{y}.pbf`
          ],
          minzoom: 15,
          maxzoom: 18
        }
      },
      glyphs: './mapfonts/{fontstack}/{range}.pbf',
      layers: [
        {
          id: 'background',
          type: 'background',
          paint: {
            'background-color': '#111',
            'background-opacity': 0
          }
        },
        {
          id: 'dc-fill',
          type: 'fill',
          source: 'impervious',
          'source-layer': 'impervious',
          paint: {
            'fill-color': dataDrivenStyle,
            'fill-opacity': 0.05
          }
        },
        {
          id: 'dc-lines',
          type: 'line',
          source: 'impervious',
          'source-layer': 'impervious',
          paint: {
            'line-color': dataDrivenStyle,
            'line-width': 0.5
          }
        },
        {
          id: 'dc-trees-circle',
          type: 'circle',
          source: 'dctrees1',
          'source-layer': 'dctrees1',
          paint: {
            'circle-color': treeStyle,
            'circle-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.5],
            'circle-blur': 1,
            'circle-radius': ['case', ['boolean', ['feature-state', 'hover'], false], 30, 10],
            'circle-stroke-color': treeStyle,
            'circle-stroke-width': 1
          }
        },
        {
          id: 'dc-lines-extrusion',
          type: 'fill-extrusion',
          source: 'impervious',
          'source-layer': 'impervious',
          paint: {
            'fill-extrusion-color': dataDrivenStyle,
            'fill-extrusion-height': 20,
            'fill-extrusion-opacity': 0.3,
            'fill-extrusion-base': 0
          },
          filter: ['any', ['==', 'DESCRIPTIO', 'Building'], ['==', 'DESCRIPTIO', 'Memorial']]
        },
        {
          id: 'dc-poi',
          type: 'symbol',
          source: 'poi',
          'source-layer': 'poi',
          minzoom: 3,
          layout: {
            'text-field': '{ALIASNAME}',
            'text-font': ['Open Sans Semibold'],
            'text-transform': 'uppercase',
            'text-max-width': 20,
            'text-size': {
              stops: [
                [12, 6],
                [13, 6],
                [14, 6],
                [15, 6],
                [16, 6],
                [17, 6],
                [18, 6]
              ]
            }
          },
          paint: {
            'text-color': labelColor
          }
        }
      ]
    };

    this.loadADSB();
    this.loadElevations();
    this.setupHighwayText();
    //this.animate();
  }

  componentWillUnmount() {
    if (this.animationFrame) {
      window.cancelAnimationFrame(this.animationFrame);
    }
    if (this.rotationId) {
      clearInterval(this.rotationId);
    }
  }

  componentDidUpdate(prevProps) {
    const {features, isExploring} = this.props;

    if (features && features !== prevProps.features) {
      this.setState({
        features
      });
    }

    if (isExploring && this.rotationId) {
      clearInterval(this.rotationId);
    }
  }

  loadADSB = () => {
    fetch('../adsb.csv')
      .then(response => response.text())
      .then(responseText => {
        const flights = csvParse(responseText, (d, i) => {
          if (!isNaN(d.longitude)) {
            return {
              tail: d.tail,
              coordinates: [+d.longitude, +d.latitude, +d.altitude],
              color: this.getDeckColor(flightColors.next(d.tail))
            };
          }
        });

        this.setState({
          flights
        });
      });
  };

  loadElevations = () => {
    fetch('../dcspotelevations.csv')
      .then(response => response.text())
      .then(responseText => {
        const elevationRange = [0, 0];
        const spotElevations = csvParse(responseText, (d, i) => {
          const elevation = +d.ELEVATION;
          elevationRange[0] = Math.min(elevationRange[0], elevation);
          elevationRange[1] = Math.max(elevationRange[1], elevation);
          return {
            coordinates: [+d.X, +d.Y, elevation],
            elevation: elevation
          };
        });
        const deltaElevation = elevationRange[1] - elevationRange[0];
        const delta = deltaElevation / 100;
        const contours = [];
        let ele = elevationRange[0];
        const elevationColor = scaleSequential(interpolateCool).domain(elevationRange);
        while (ele < elevationRange[1]) {
          contours.push({
            threshold: ele,
            color: this.getElevationColor(ele, elevationColor),
            strokeWidth: 1
          });
          ele += delta;
        }
        this.setState({
          spotElevations,
          elevationRange,
          elevationColor,
          contours
        });
      });
  };

  setupHighwayText = () => {
    const pointFeatures = [];
    const pointFeaturePaths = [];
    const pointFeatureText = [];
    highways.features.forEach(feature => {
      feature.geometry.coordinates.forEach(line => {
        const lineFeature = lineString(line);
        const lineLength = length(lineFeature);
        let start = 0;
        while (start < lineLength) {
          const pointFeature = along(lineFeature, start);
          const text = Math.random().toFixed(0);
          pointFeatureText.push(text);
          pointFeature.properties.text = text;
          pointFeatures.push(pointFeature);
          start += TEXT_DISTANCE;
        }
      });
    });
    this.setState({
      pointFeatures,
      pointFeaturePaths
    });
    this.pointFeatureText = pointFeatureText;
  };

  getElevationColor = (elevation, elevationColor) => {
    const colorVal = elevationColor(elevation);
    const colorObj = color(colorVal);
    return [colorObj.r, colorObj.g, colorObj.b, 255];
  };

  flyToPoint = ({longitude, latitude, zoom, pitch, bearing, transitionDuration = 1500}) => {
    const newViewState = {...this.state.viewState};
    newViewState.longitude = longitude;
    newViewState.latitude = latitude;
    newViewState.zoom = zoom || newViewState.zoom;
    newViewState.pitch = pitch;
    newViewState.bearing = bearing;
    newViewState.transitionDuration = transitionDuration;
    newViewState.transitionInterpolator = new FlyToInterpolator();
    this.setState({
      viewState: newViewState
    });
  };

  flyToDC = () => {
    this.flyToPoint({
      longitude: -77.023,
      latitude: 38.8896,
      zoom: 17,
      pitch: 30,
      bearing: 90
    });
  };

  flyOut = () => {
    this.flyToPoint({
      longitude: -77.023,
      latitude: 38.8896,
      zoom: 15,
      pitch: 30,
      bearing: 70
    });
  };

  flyToLeftOfLogo = () => {
    const {mapWidth} = this.state;
    this.flyToPoint({
      latitude: 38.902527,
      longitude: -77.052644,
      zoom: mapWidth > MOBILE_WIDTH ? 17 : 16.1,
      pitch: 0,
      bearing: 0
    });
  };

  flyToLogo = () => {
    const {mapWidth} = this.state;
    // -77.050872, 38.901924, -77.049329, 38.903152
    this.flyToPoint({
      longitude: -77.0501005,
      latitude: 38.902538,
      zoom: mapWidth > MOBILE_WIDTH ? 18 : 17,
      pitch: 45,
      bearing: 30
    });
  };

  flyRotate = () => {
    const {isExploring} = this.props;
    const {mapWidth} = this.state;

    if (isExploring && this.rotationId) {
      clearInterval(this.rotationId);
    }

    if (!isExploring) {
      this.flyToPoint({
        longitude: -77.0501005,
        latitude: 38.902538,
        zoom: mapWidth > MOBILE_WIDTH ? 16.7 : 16.1,
        //zoom: 10.1 + 8 * Math.random(),
        pitch: 45,
        bearing: Math.random() * 360
      });
    }
  };

  startRotation = () => {
    const {isExploring} = this.props;

    if (!isExploring) {
      this.rotationId = setInterval(this.flyRotate, 5000);
    }
  };

  startTour = () => {
    setTimeout(this.flyToLeftOfLogo, 500);
    setTimeout(this.flyToLogo, 7500);
    setTimeout(this.startRotation, 15000);
  };

  onDeckRef = ref => {
    // save a reference to the Deck instance
    this.deck = ref && ref.deck;
  };

  onMapRef = ref => {
    // save a reference to the mapboxgl.Map instance
    this.map = ref && ref.getMap();
    if (this.map) {
      this.setState({
        mapWidth: this.map._canvas.clientWidth || this.map._canvas.offsetWidth
      });
      this.map.on('load', () => {
        const backgroundLayer = this.map.getLayer('background');
        if (backgroundLayer) {
          this.map.setPaintProperty('background', 'background-opacity', 1);
        }
        this.startTour();
      });
    }
  };

  /**
   * Returns a deck.gl TileLayer that dynamically updates data on zoom and displays tiles as GeoJsonLayer instances
   * The getTilePromise method constructs a query to the backend service based on the bbox for each tiles.  Note the props
   * property matches the props for GeoJsonLayer
   * @param {*} url
   * @param {*} props
   */
  getDynamicGeoJsonLayer = (url, viewport, props) => {
    // TODO:  renderSubLayers would allow us to display text along with data
    // By default, renderSubLayers for TileLayer returns a GeoJsonLayer
    const {getTilePromise, ...layerProps} = props;
    return new TileLayer({
      ...layerProps,
      getTileData: ({x, y, z}) => {
        const bboxVal = tile2boundingBox(x, y, z);
        return getTilePromise(x, y, z, bboxVal);
      },
      onViewportLoaded: data => {}
    });
  };

  /**
   * Returns a deck.gl TileLayer that's wired to talk to an ESRI Feature Service
   * TODO:  Support ESRI queries via the WHERE clause
   */
  getFeatureLayer = (url, viewport, props = {}) => {
    this._featureIndex = this._featureIndex || {};
    this._tileIndex = this._tileIndex || {};
    const {format = 'pgeojson', outFields = '*'} = props;
    const getTilePromisePGeoJson = (x, y, z, bboxVal) => {
      const {west, south, east, north} = bboxVal;
      const bboxString = [west, south, east, north].join(',');
      const bboxStringEncoded = encodeURIComponent(bboxString);
      const formatName = 'geojson';
      const tileUrl = `${url}/query?where=&f=${formatName}&geometry=${bboxStringEncoded}&geometryType=esriGeometryEnvelope&inSR=4326&outSR=4326&spatialRel=esriSpatialRelIntersects&outFields=${outFields}`;

      return fetch(tileUrl)
        .then(response => response.json())
        .then(data => data.features);
    };

    const getTilePromisePBF = (x, y, z) => {
      // const { west, south, east, north } = bboxVal;
      // const bboxString = [west, south, east, north].join(',');
      // const bboxStringEncoded = encodeURIComponent(bboxString);
      // const formatName = 'pbf';
      // const tileUrl = `${url}/query?where=&f=${formatName}&geometry=${bboxStringEncoded}&geometryType=esriGeometryEnvelope&inSR=4326&outSR=4326&spatialRel=esriSpatialRelIntersects&outFields=*`;
      const tileUrl = `${url}/${z}/${x}/${y}.pbf`;
      if (tileUrl in this._tileIndex) {
        return Promise.resolve(this._tileIndex[tileUrl]);
      }
      return fetch(tileUrl)
        .then(response => response.arrayBuffer())
        .then(buffer => {
          const tile = new VectorTile(new Protobuf(buffer));
          const features = [];
          for (const layerName in tile.layers) {
            const vectorTileLayer = tile.layers[layerName];
            for (let i = 0; i < vectorTileLayer.length; i++) {
              const vectorTileFeature = vectorTileLayer.feature(i);
              const feature = vectorTileFeature.toGeoJSON(x, y, z);
              const existingFeature = this._featureIndex[feature.properties.OBJECTID];
              let unionedFeature = feature;
              try {
                if (existingFeature) {
                  unionedFeature = union(feature, existingFeature);
                }
              } catch (ex) {
                console.log(ex);
              }
              this._featureIndex[feature.properties.OBJECTID] = unionedFeature;
              //features.push(feature);
              features.push(this._featureIndex[feature.properties.OBJECTID]);
            }
          }
          this._tileIndex[tileUrl] = features;
          return features;
        });
    };
    const promiseFuncs = {
      pbf: getTilePromisePBF,
      pgeojson: getTilePromisePGeoJson
    };
    return this.getDynamicGeoJsonLayer(url, viewport, {
      ...props,
      getTilePromise: promiseFuncs[format]
    });
  };

  onViewStateChange = ({viewState}) => {
    this.setState({viewState});
  };

  getFillColor = (feature, opacity) => {
    const featureColor = featureColors.next(feature.properties.DESCRIPTIO);
    const colorObj = color(featureColor);
    return [colorObj.r, colorObj.g, colorObj.b, opacity];
  };

  getLineColor = feature => {
    const featureColor = featureColors.next(feature.properties.DESCRIPTIO);
    const colorObj = color(featureColor);
    return [colorObj.r, colorObj.g, colorObj.b, 255];
  };

  animate(currentTime) {
    const {
      loopLength = 60000, // unit corresponds to the timestamp in source data
      animationSpeed = 500 // unit time per second
    } = this.props;
    this.start = this.start || currentTime;
    const progress = currentTime - this.start;
    const timestamp = Date.now() / 1000;
    const loopTime = loopLength / animationSpeed;
    const time = ((timestamp % loopTime) / loopTime) * loopLength;

    if (progress > 90) {
      const {pointFeatures} = this.state;
      if (this.pointFeatureText && pointFeatures.length > 0) {
        this.pointFeatureText.unshift(this.pointFeatureText.pop());
        this.setState({
          time
        });
      }
      this.start = currentTime;
    }
    this.animationFrame = window.requestAnimationFrame(this.animate.bind(this));
  }

  renderTooltip = () => {
    const {hoveredObject, pointerX, pointerY} = this.state;
    if (hoveredObject) {
      return (
        <div
          className="map-tooltip"
          style={{
            padding: '1rem',
            zIndex: 2,
            position: 'absolute',
            top: pointerY,
            left: pointerX
          }}
        >
          <p>{JSON.stringify(hoveredObject)}</p>
        </div>
      );
    }
  };
  renderLayers = viewState => {
    // const random = Math.random();
    const layers = [
      /*
            this.getFeatureLayer('https://maps2.dcgis.dc.gov/dcgis/rest/services/DCGIS_DATA/Environment_WebMercator/MapServer/50', {
                outFields: 'DESCRIPTION',
                getFillColor: f => this.getFillColor(f),
                getLineColor: f => this.getLineColor(f),
                extruded: true,
                wireframe: true,
                filled: false,
                stroked: true,
                getLineWidth: 30,
                lineWidthMinPixels: 5,
                lineWidthScale: 20,
                getElevation: f => f.properties.DESCRIPTION === 'Building' ? 10 : 0,
                transitions: {
                    getFillColor: {
                        duration: 300,
                    },
                    getLineColor: {
                        duration: 300,
                    }
                },
            }),
            */
      /*
           this.getFeatureLayer('http://localhost:8001/static/dc-tiles2', {
            //outFields: 'DESCRIPTION',
            format: 'pbf',
            getFillColor: f => this.getFillColor(f),
            getLineColor: f => this.getLineColor(f),
            extruded: true,
            wireframe: true,
            filled: false,
            stroked: true,
            getLineWidth: 30,
            lineWidthMinPixels: 5,
            lineWidthScale: 20,
            minZoom: 12,
            maxZoom: 18, 
            getElevation: f => f.properties.DESCRIPTIO === 'Building' ? 10 : 0,
            transitions: {
                getFillColor: {
                    duration: 300,
                },
                getLineColor: {
                    duration: 300,
                }
            },
        }),
        */
      /*
            new GeoJsonLayer({
                id: 'features',
                data: this.state.features,
                stroked: true,
                filled: false,
                extruded: true,
                wireframe: true,
                getElevation: f => f.properties.elevation,
                getFillColor: f => f.properties.color,
                getLineColor: f => f.properties.color,
                getLineWidth: f => 10,
                parameters: {
                    // prevent flicker from z-fighting
                    [GL.DEPTH_TEST]: false,
                },
                fp64: false,
                visible: viewState.zoom > 13,
            }),*/
      /*
            new PointCloudLayer({
                id: 'point-features',
                data: this.state.pointFeatures,
                stroked: true,
                filled: true,
                getPosition: f => f.geometry.coordinates,
                getColor: f => [255, 0, 0, f.properties.text === '1' ? 128 : 32],
                parameters: {
                    // prevent flicker from z-fighting
                    [GL.DEPTH_TEST]: false,
                },
                fp64: false,
                visible: viewState.zoom > 15
            }),
            */
      /*
            new ScatterplotLayer({
                id: 'scatterplot-layer',
                data: this.state.pointFeatures,
                opacity: 0.8,
                stroked: true,
                filled: false,
                radiusScale: 1,
                radiusMinPixels: 1,
                radiusMaxPixels: 10,
                lineWidthMinPixels: 1,
                lineWidthMaxPixels: 1,
                getPosition: d => d.geometry.coordinates,
                getRadius: d => 3,
                getLineColor: d => [255, 128, 0, Math.random().toFixed(0) === '1' ? 255 : 0]
            }),
            */
      /*
            new TripsLayer({
                id: 'trips-layer',
                data: this.state.pointFeaturePaths,
                getPath: d => d.map(p => p.geometry.coordinates),
                // deduct start timestamp from each data point to avoid overflow
                getTimestamps: d => d.map((p, i) => i * 1000),
                getColor: [253, 128, 93],
                opacity: 0.8,
                widthMinPixels: 4,
                rounded: true,
                trailLength: 30000,
                currentTime: this.state.time
            }),
            */
      new TextLayer({
        id: 'point-text',
        data: this.state.pointFeatures,
        getPosition: f => f.geometry.coordinates,
        getText: (f, fInfo) => this.pointFeatureText[fInfo.index], // f.properties.text,
        getColor: dataColorDeck,
        getSize: 16,
        getAngle: 0,
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center',
        transitions: {
          getText: {
            duration: 300
          }
        },
        updateTriggers: {
          getText: [this.state.time]
        },
        visible: viewState.zoom > 16
      }),
      /*
            new PointCloudLayer({
                id: 'scatterplot',
                data: this.state.features,
                filled: true,
                getPosition: f => [f.properties.center[0], f.properties.center[1], (this.state.time % 50) * Math.random()],
                // getFillColor: f => f.properties.color,
                //getColor: f => [this.state.time % 2 * f.properties.color[0], f.properties.color[1], f.properties.color[2], f.properties.color[3]],
                getColor: f => f && [dataColorDeck[0], dataColorDeck[1], dataColorDeck[2], f.properties.color[3] * Math.random()],
                material: new PhongMaterial({
                    ambient: 0.2,
                    diffuse: 0.5,
                    shininess: 128,
                    specularColor: dataColorDeck
                }),
                pointSize: 2, //f.properties.elevation,
                opacity: 0.9,
                parameters: {
                    // prevent flicker from z-fighting
                    [GL.DEPTH_TEST]: false,
    
                    // turn on additive blending to make them look more glowy
                    [GL.BLEND]: true,
                    [GL.BLEND_SRC_RGB]: GL.ONE,
                    [GL.BLEND_DST_RGB]: GL.ONE,
                    [GL.BLEND_EQUATION]: GL.FUNC_ADD,
                },
                transitions: {
                    getPosition: {
                        duration: 300
                    }
                },
                updateTriggers: {
                    getPosition: [this.state.time]
                }
    
            }),
            */
      new GeoJsonLayer({
        id: 'washington-monument',
        data: monumentBands,
        stroked: false,
        filled: true,
        extruded: true,
        //wireframe: true,
        getElevation: (f, fInfo) => 200 + fInfo.index * 0.15,
        getFillColor: f => this.getFillColor(f, 32),
        getLineColor: [0, 0, 0, 0],
        getLineWidth: 10,
        parameters: {
          // prevent flicker from z-fighting
          [GL.DEPTH_TEST]: false
        }
      }),
      new TextLayer({
        id: 'text',
        data: this.state.features,
        getPosition: f => f.properties.center,
        getText: f => Math.random().toFixed(0),
        getColor: f =>
          f && [dataColorDeck[0], dataColorDeck[1], dataColorDeck[2], f.properties.color[3] * 2 * Math.random()],
        getSize: 2,
        sizeUnits: 'meters',
        getAngle: 0, // f => Math.random() * this.state.time,
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center',
        transitions: {
          getText: {
            duration: 300
          },
          getColor: {
            duration: 300
          }
        },
        updateTriggers: {
          //getAngle: [this.state.time],
          getColor: [this.state.time]
          //getText: [this.state.time]
        },
        visible: viewState.zoom > 16
      }),
      new PointCloudLayer({
        id: 'elevation',
        data: this.state.spotElevations,
        pickable: true,
        autoHighlight: true,
        getPosition: f => [
          f.coordinates[0],
          f.coordinates[1],
          f.coordinates[2] * (viewState.zoom < 14 ? 14 - viewState.zoom : 0)
        ],
        pointSize: 1,
        getColor: f => {
          const colorVal = this.state.elevationColor(f.coordinates[2]);
          const colorObj = color(colorVal);
          return [colorObj.r, colorObj.g, colorObj.b, 255];
        },
        updateTriggers: {
          getPosition: [viewState.zoom]
        },
        transitions: {
          getPosition: {
            duration: 300
          }
        },
        onHover: ({object, x, y}) => {
          this.setState({
            hoveredObject: object,
            pointerX: x,
            pointerY: y
          });
        }
      }),
      new GeoJsonLayer({
        id: 'highlights',
        data: this.state.highlightedFeatures,
        stroked: true,
        filled: false,
        extruded: true,
        wireframe: true,
        getElevation: f => f.properties.elevation || 0,
        getFillColor: f => f.properties.color,
        getLineColor: f => f.properties.color,
        getLineWidth: f => 10,
        parameters: {
          // prevent flicker from z-fighting
          [GL.DEPTH_TEST]: false
        }
      }),
      new ArcLayer({
        id: 'fountain-arcs',
        data: this.state.fountainArcs,
        getSourcePosition: f => f.from,
        getTargetPosition: f => f.to,
        getSourceColor: f => [f.color[0], f.color[1], f.color[2], 0],
        getTargetColor: f => f.color
      }),
      new PointCloudLayer({
        id: 'fountain',
        data: this.state.fountainFeature,
        getPosition: f => f.geometry.coordinates,
        pointSize: 1,
        getColor: f => f.properties.color
      }),
      new GeoJsonLayer({
        id: 'building-highlight',
        data: this.state.buildingFeature,
        stroked: true,
        filled: false,
        extruded: true,
        wireframe: true,
        getElevation: f => 20,
        getFillColor: f => f.properties.color,
        getLineColor: f => f.properties.color,
        getLineWidth: f => 10,
        visible: this.state.buildingFeature,
        parameters: {
          // prevent flicker from z-fighting
          [GL.DEPTH_TEST]: false
        }
      }),
      new TextLayer({
        id: 'highlight-text',
        data: this.state.highlightedFeatures,
        getPosition: f => f.info.lngLat,
        getText: f => {
          switch (f.source) {
            case 'impervious':
              return f.properties.DESCRIPTIO;
            case 'dctrees1':
              return `${f.properties.CMMN_NM} (${f.properties.CONDITION})`;
            case 'poi':
              return f.properties.ALIASNAME;
            default:
              return 'Unknown';
          }
        },
        getColor: f => f.deckColor,
        getSize: 20,
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center',
        getPixelOffset: (f, fInfo) => {
          return [0, fInfo.index * 25];
        }
      }),
      new PointCloudLayer({
        id: 'flights',
        data: this.state.flights,
        pickable: true,
        autoHighlight: true,
        getPosition: f => f.coordinates,
        pointSize: 1,
        getColor: f => f.color,
        onHover: ({object, x, y}) => {
          this.setState({
            hoveredObject: object,
            pointerX: x,
            pointerY: y
          });
        }
      })
    ];
    return layers;
  };

  getDeckColor = (colorVal, opacity) => {
    const colorObj = color(colorVal);
    return [colorObj.r, colorObj.g, colorObj.b, opacity || 255];
  };

  highlightFeatures = (info, event, method) => {
    let fountainFeature = {
      features: []
    };
    const fountainArcs = [];
    let buildingFeature = null;
    let uniqueSources = {};
    let mapboxFeatures = [];
    if (info && this.map) {
      mapboxFeatures = this.map
        .queryRenderedFeatures([info.x, info.y])
        .filter(feature => {
          const {source} = feature;
          if (!uniqueSources[source]) {
            uniqueSources[source] = true;
            return true;
          }
          return false;
        })
        .map(feature => {
          feature.color =
            feature.source === 'impervious' || feature.source === 'poi'
              ? featureColors.next(feature.properties.DESCRIPTIO || 'Labels')
              : treeColors.next(feature.properties.SCI_NM);
          feature.deckColor = this.getDeckColor(feature.color);
          feature.info = info;
          const {DESCRIPTIO} = feature.properties;
          if (DESCRIPTIO === 'Fountain' || (DESCRIPTIO && DESCRIPTIO.indexOf('Pool') > -1)) {
            const fullFeatures = this.map.querySourceFeatures('impervious', {
              sourceLayer: 'impervious',
              filter: ['==', 'OBJECTID', feature.properties.OBJECTID]
            });
            if (fullFeatures.length > 0) {
              const fullFeature = union(...fullFeatures);
              const bounds = bbox(fullFeature);
              fountainFeature = pointGrid(bounds, 0.001, {
                units: 'miles',
                mask: fullFeature
              });
              [0.001, 0.002, 0.003, 0.004, 0.005].forEach((radius, index) => {
                if (info.lngLat) {
                  const circleFeature = circle(info.lngLat, radius, {
                    units: 'miles',
                    steps: Math.floor(Math.random() * 64) + 8
                  });
                  const circlePoints = explode(circleFeature);
                  fountainFeature.features = fountainFeature.features.concat(circlePoints.features);
                  circlePoints.features.forEach(circlePoint =>
                    fountainArcs.push({
                      from: info.lngLat,
                      to: circlePoint.geometry.coordinates,
                      color: [
                        feature.deckColor[0],
                        feature.deckColor[1],
                        feature.deckColor[2],
                        (128 / index) * Math.random()
                      ]
                    })
                  );
                }
              });
              fountainFeature.features.forEach(subFeature => {
                subFeature.properties.color = feature.deckColor;
              });
            }
          } else if (feature.properties.DESCRIPTIO === 'Building' || feature.properties.DESCRIPTIO === 'Memorial') {
            const fullFeatures = this.map.querySourceFeatures('impervious', {
              sourceLayer: 'impervious',
              filter: ['==', 'OBJECTID', feature.properties.OBJECTID]
            });
            if (fullFeatures.length > 0) {
              buildingFeature = union(...fullFeatures);
              buildingFeature.properties.color = this.getDeckColor(feature.color);
            }
          }
          return feature;
        });
    }
    this.setState({
      highlightedFeatures: mapboxFeatures,
      fountainFeature: fountainFeature.features,
      fountainArcs,
      buildingFeature
    });

    if (method === 'click') {
      if (this.animationFrame) {
        window.cancelAnimationFrame(this.animationFrame);
        this.animationFrame = null;
      } else {
        this.animate();
      }
    }
  };

  render() {
    const {viewState} = this.state;
    const layers = this.renderLayers(viewState);

    return (
      <div style={{zIndex: 1}}>
        <DeckGL
          ref={this.onDeckRef}
          viewState={viewState}
          onViewStateChange={this.onViewStateChange}
          controller={true}
          layers={layers}
          onClick={(info, event) => this.highlightFeatures(info, event, 'click')}
          onHover={(info, event) => this.highlightFeatures(info, event, 'hover')}
        >
          <StaticMap
            {...viewState}
            ref={this.onMapRef}
            mapStyle={layerConfig}
            mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
          />

          {this.renderTooltip()}
        </DeckGL>
      </div>
    );
  }
}

export default Map;
