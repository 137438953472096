import {graphql, Link} from 'gatsby';
import React from 'react';
import {Helmet} from 'react-helmet';
import ArticleGrid from '../components/ArticleGrid';
import Banner from '../components/Banner';
import Layout from '../components/layout';

const HomeIndex = ({data}) => {
  return (
    <Layout>
      <Helmet
        title="Black Cape"
        meta={[
          {
            name: 'description',
            content: 'Heroic Solutions for Government and Industry'
          },
          {
            name: 'keywords',
            content: 'software, mission applications, cloud, data analytics, machine learning, artificial intelligence'
          }
        ]}
      />
      <Banner data={data} />
      <div id="main">
        <section id="aboutus" style={{position: 'relative'}}>
          <div className="inner">
            <header className="major">
              <h2>About Us</h2>
            </header>
            <p>
              Hard problems call for Heroic Solutions. We're experts in developing custom Mission Applications,
              employing novel Data Analytics, and applying powerful Machine Learning and Artificial Intelligence
              capabilities to help our Government and Commercial clients harness and make sense of their data.
            </p>
            <ul className="actions">
              <li>
                <Link to="/company" className="button next">
                  Learn More
                </Link>
              </li>
            </ul>
          </div>
          <div
            className="triangle triangle-bottom-big"
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              width: 0,
              height: 0,
              borderStyle: 'solid',
              //borderWidth: '0 18rem 16rem 0',
              borderColor: 'transparent rgba(34, 34, 34, 0.2) transparent transparent'
            }}
          />
          <div
            className="triangle triangle-bottom-small"
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              width: 0,
              height: 0,
              borderStyle: 'solid',
              //borderWidth: '0 14rem 12rem 0',
              borderColor: 'transparent rgba(34, 34, 34, 0.2) transparent transparent'
            }}
          />
        </section>
        <section
          id="focus"
          style={{
            background: 'linear-gradient(180deg, rgba(122, 0, 234, 0), #222)'
          }}
        >
          <div className="inner">
            <header className="major">
              <h2>Focus Areas</h2>
            </header>
            <ArticleGrid articleType={'focus'} />
          </div>
        </section>
        <section id="products" className="major" style={{background: '#222'}}>
          <div className="inner">
            <header className="major">
              <h2>Products</h2>
            </header>
            <ArticleGrid articleType={'products'} />
          </div>
        </section>
        <section id="news" className="major" style={{background: '#222'}}>
          <div className="inner">
            <header className="major">
              <h2>News</h2>
            </header>
            <ArticleGrid articleType={'news'} count={4} />
            <ul className="actions" style={{marginTop: '2em'}}>
              <li>
                <Link to="/news" className="button next">
                  All News
                </Link>
              </li>
            </ul>
          </div>
        </section>
        <section id="blog" className="major" style={{background: '#222'}}>
          <div className="inner">
            <header className="major">
              <h2>Blog</h2>
            </header>
            <ArticleGrid articleType={'blog'} count={4} />
            <ul className="actions" style={{marginTop: '2em'}}>
              <li>
                <Link to="/blog" className="button next">
                  All Blog Posts
                </Link>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    file(relativePath: {eq: "banner2.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default HomeIndex;
