import Img from 'gatsby-image';
import React from 'react';
import TextLoop from 'react-text-loop';
import pic from '../assets/images/bclogo_notext.png';
import GeoImage from './GeoImage';
import Map from './Map';

const skillSet = ['collect', 'enrich', 'process', 'exploit', 'visualize', 'understand', 'harness'];
const bounds = [-77.050872, 38.901924, -77.049329, 38.903152];

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      features: [],
      isExploring: false
    };
  }

  explore = () => {
    this.setState({
      isExploring: true
    });
  };

  render() {
    const {features, isExploring} = this.state;
    const {data} = this.props;
    return (
      <section id="banner" className={`main major ${isExploring ? 'isExploring' : ''}`} style={{position: 'relative'}}>
        <GeoImage image={pic} bounds={bounds} onFeatures={pixelFeatures => this.setState({features: pixelFeatures})} />
        <Map features={features} isExploring={isExploring} />
        {data.file && (
          <Img
            fluid={data.file.childImageSharp.fluid}
            style={{
              opacity: '0.6',
              width: '100%',
              height: '38.25em',
              position: 'absolute',
              top: '0',
              zIndex: '0'
            }}
          />
        )}
        {!isExploring && (
          <div className="inner">
            <header className="major">
              <h1>
                We help our clients&nbsp;
                <TextLoop children={skillSet} className="skill" />
                &nbsp;data.
              </h1>
            </header>
            <div className="content">
              <p>Heroic Solutions for Government and Industry</p>
              <ul className="actions">
                <li>
                  <a className="button next scrolly" onClick={this.explore}>
                    Explore
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
        <div
          className="triangle triangle-top-big"
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderColor: 'transparent transparent rgba(255, 255, 255, 0.4) transparent',
            pointerEvents: 'none'
          }}
        />
        <div
          className="triangle triangle-top-small"
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderColor: 'transparent transparent rgba(255, 255, 255, 0.4) transparent',
            pointerEvents: 'none'
          }}
        />
      </section>
    );
  }
}

export default Banner;
