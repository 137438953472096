import {graphql, Link, StaticQuery} from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

export default ({articleType, count = 100}) => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(limit: 100, sort: {order: DESC, fields: [frontmatter___date]}) {
          edges {
            node {
              frontmatter {
                author
                date(formatString: "MMMM DD, YYYY")
                path
                title
                description
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 70) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const {edges} = data.allMarkdownRemark;
      const relevantEdges = edges
        .filter(edge => {
          return edge.node.frontmatter.path.indexOf(`/${articleType}`) === 0;
        })
        .slice(0, count);
      const numEdges = relevantEdges.length;
      const isOdd = numEdges % 2 === 1;
      return (
        <div className="tiles">
          {relevantEdges.map((edge, edgeIndex) => {
            let className = '';
            const {frontmatter} = edge.node;
            const {author, date, description, featuredImage, path, title} = frontmatter;
            const isBlog = path.indexOf('blog') > -1;
            let articleDescription = date || description;
            if (isBlog) {
              articleDescription = `${author.toUpperCase()} / ${articleDescription}`;
            }
            if (isOdd && edgeIndex === numEdges - 1) {
              className = 'full';
            }
            return (
              <article className={className} key={`article-${edgeIndex}`}>
                <Img
                  fluid={featuredImage.childImageSharp.fluid}
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: '0px',
                    left: '0px'
                  }}
                />
                <header className="major">
                  <h3>{title}</h3>
                  <p>{articleDescription}</p>
                </header>
                <Link to={path} className="link primary"></Link>
              </article>
            );
          })}
        </div>
      );
    }}
  />
);
